/* new navbar */
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/* dark #d39d1f */
/* #ecb434 */
/* light #fbeb7a */
.navbar {
    margin: 0 !important;
    padding: 0 !important;
    transition: all 0.5s linear;
    /* background-color: #303030; */
    background-color: white;
    z-index: 1111;
}

.nav-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 123px;

    transition: all 0.5s linear;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    font-size: 25px;
    color: white;
    width: 100%;
    transition: all 0.5s linear;
    align-items: center;
}

.scrolled {
    position: sticky;
    top: 0;
    left: 0;
    /* background-color: #303030ed !important; */
    /* background-color: rgba(255, 255, 255, .9); */
    background-color: white;
    padding: 0;
    z-index: 2222;
    transition: all 0.5s linear;
    /* box-shadow: 0 20px 50px rgba(0, 0, 0, 0.05); */
}

.scrolled .nav-container .logo {
    width: 200px;
    transition: all 0.5s linear;
}

.nav-container .logo {
    width: 200px;
}

.nav-container .logo img {
    width: 200px;
}



a {
    text-decoration: none;
}

ul {
    /* padding: 0; */
    list-style-type: none;
}

.nav-container ul {
    display: flex;
    margin: 0;
}

.nav-container ul li {

    margin: 0 10px;
    font-size: 15px;
    color: black;
    cursor: pointer;
    position: relative;

}

.nav-container ul li a {
    color: #303030;
    text-transform: uppercase;
    font-weight: 800;
}

.nav-container ul li a:hover {
    color: #9F181F;
}

.nav-container ul li svg {
    font-size: 20px;
}

.nav-container ul li .dropdown-ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    z-index: 11;
    background: white;
    /* padding: 16px 23px; */
    width: 246px;
    padding: 13px;
    left: -26%;
    visibility: hidden;
    transition: all 0.5s linear;
    border-top: 3px solid #9F181F;
}

/* .scrolled to dropdown */
.scrolled .nav-container ul li .dropdown-ul {
    top: 201%;
}

.nav-container ul li .dropdown-ul li {
    margin-bottom: 10px;
}

.nav-container ul li:hover .dropdown-ul {
    visibility: visible;
}

.search-section input {
    border: 1px solid gainsboro;
    padding: 12px;
    border-radius: 5px;
    font-size: 14px;
}

*:focus {
    outline: none;
    border: none;
}

.nav-section {
    position: relative;
}

.bar-section {
    display: none;
    color: #303030;
}

@media (max-width:1000px) {
    .bar-section {
        display: block;

    }

    #root {
        overflow-x: hidden !important;
    }

    .nav-section {
        padding: 10px 50px;
    }

    .nav-container .ul {
        position: absolute;
        background: white;
        display: block;
        width: 50%;
        right: 0%;
        overflow: hidden;
        top: 0%;
        z-index: 55;
        padding: 30px;
        padding-bottom: 32px;
        padding-top: 100px;
        visibility: hidden;
        opacity: 0;
        transform: translateX(50%);
        transition: all 0.5s linear;

    }

    .nav-container .ul li a {
        font-weight: 600 !important;
    }

    .nav-container .ul.active {
        visibility: visible;
        opacity: 1;
        transform: translateX(0%);
    }

    .cross-icons {
        position: absolute;
        z-index: 555555555555;
        top: 32px;
        right: 57px;
    }

    .nav-container ul li .dropdown-ul {
        position: relative;
        visibility: visible;
        display: flex;
        flex-direction: column;
        top: 0;
        z-index: 11;
        background: transparent;
        /* padding: 16px 23px; */
        /* width: 246px; */
        padding: 13px;
        left: 0;
        transition: all 5.5s linear;
        border-top: none;
        display: none;
    }

    .nav-container ul li .dropdown-ul.active {
        /* transition: all 5.5s linear; */
        display: block;
    }

    .nav-container .logo img {
        width: 100%;
    }

    .nav-section {
        position: relative;
    }

    .phone-li {
        position: relative;
    }

    .nav-container ul li {
        margin: 13px 0;
    }

    .nav-container ul li span {
        position: absolute;
        right: 0;
    }
}

@media screen and (max-width: 400px) and (min-width:350px) {
    .nav-container .ul {
        top: 100%;
        width: 78%;
        padding-top: 0;
    }

}