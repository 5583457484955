@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Yantramanav:wght@100;300;400;500;700;900&display=swap');

body {
  font-family: "Nunito Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
li,
p {
  font-family: "Nunito Sans", sans-serif;
}

.transition {
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;


}

.popular-img-box {
  position: relative;
  display: block;
  width: 99%;
  overflow: hidden;
  height: 380px;

}


.popular-img-box a {
  color: white
}

.view-tour {
  color: tomato !important;
  font-weight: 700;
}

.tour-count {
  position: absolute;
  right: 10px;
  top: 10px;
  background: #9F181F;
  /* background-color: rgb(0, 0, 0, .6); */
  /* background-color: rgb(255 255 255 / 0.3); */
  backdrop-filter: blur(10px);
  padding: 9px 15px;
  font-size: 17px;
  font-weight: 500;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 15px;
}

.overlay {
  opacity: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, .6);
  padding: 20px;
  text-align: center;
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.popular-img-box h5 {
  position: relative;
  transition: .5s;
  /* left: 500px */
}

.popular-img-box .slow-animations {
  position: relative;
  bottom: -50px;
  transition: 0.5s;

}

.popular-img-box a:hover .overlay {
  opacity: 1
}

.popular-img-box a:hover .slow-animations {
  bottom: 0;
}

.popular-img-box a:hover .show-and-remove {
  display: none;
}

.show-and-remove {
  position: absolute;
  bottom: 0;
  opacity: 1;
  background: rgba(0, 0, 0, .6);
  left: 0 !important;
  display: block;
  top: -51px;
  padding: 15px;
  text-align: center;

}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading-h3 {
  font-weight: 800;
  font-size: 30px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.margin-50 {
  margin-top: 50px !important;
}

.less-price-div {
  margin-left: 35px;
  margin-bottom: 7px !important;
}

/* a {
  background:
    linear-gradient(to right,
      rgba(100, 200, 200, 1),
      rgba(100, 200, 200, 1)),
    linear-gradient(to right,
      rgba(255, 0, 0, 1),
      rgba(255, 0, 180, 1),
      rgba(0, 100, 200, 1));
  background-size: 100% 3px, 0 3px;
  background-position: 100% 120%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
} */

/* a:hover {
  background-size: 0 3px, 100% 3px;
} */