/* homebanner */
* {
  margin: 0;
  padding: 0;
}

.heading-h1 {
  font-size: 50px;
  font-weight: bold;
}

img {
  width: 100%;
  height: 100%;
}

.home-banner {
  width: 100%;
  height: 100%;
  height: 90vh;
  position: relative;

}

.home-banner .slider-container h1 {
  font-size: 85px;
  color: white;
  line-height: 48px;
}

.home-banner img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  position: relative;
  opacity: 1;
  z-index: 1;
  filter: brightness(0.5);
}

.home-banner img::before {
  background-color: black;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.2;
  z-index: -1;
}

.text-heading-span {
  font-size: 60px;
  color: white;
  margin-bottom: 10px;
}

.home-banner .slider-container {
  position: absolute;
  top: -125px;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2;
}

.home-banner .slider-container p {
  color: white;
  font-size: 35px;
  margin-bottom: 10px;
}

.slider-search-container {
  padding-top: 25px;
}

.home-banner .slider-container input {
  padding: 12.01px;
  margin-right: 1px;
  color: black;
  width: 180px;
  font-size: 17px;
  position: relative;
  top: 0px;
  border: none;
  height: 100%;
}

.home-banner .slider-container select {
  padding: 11px 20px;
  width: 180px;
  margin-right: 1px;
  color: black;
  font-size: 16px;
  text-transform: capitalize;
  border: none;
}

*:focus {
  outline: none;
}

.search-btn {
  padding: 10px 25px;
  background-color: black;
  outline: none;
  border: 1px solid black;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.color-black {
  color: black !important;
}

.search-btn:hover {
  background-color: #9F181F;
  border-color: #9F181F;
}

.texture-area {
  position: relative;
  bottom: 0px;
  width: 80%;
  top: -50px;
  right: 0;
  left: 0;
  margin: auto;
  background-image: linear-gradient(180deg, #9F181F, rgb(255 229 224));
  z-index: 1;
}

.texture-container {
  display: flex;
  padding: 20px;

}

.texture-content {
  display: flex;
}

.texture-content img {
  width: 70px;
  height: 70px;
  margin-right: 11px;
}

a {
  text-decoration: none !important;
  border-bottom: none;

}

.heading-h5 {
  font-size: 19px;
  font-weight: 800;
  margin-bottom: 10px;
}

.para {
  font-size: 17px;
}

.texture-content {
  /* display: flex; */
  width: 33%;
  padding: 30px;
  color: white;
}

.i {
  font-style: italic;
}

.container-section {
  width: 80%;
  margin: auto;
}

.blur-text {
  color: #e6e6e6;
  font-weight: 900;
  font-size: 100px;
  position: absolute;
  top: -36px;
  z-index: -1;
  bottom: 0;
  left: 0;
  right: 0;
  top: -9px;
  text-transform: uppercase;
}

.margin-100 {
  margin-top: 100px;
}

.popular-tour-img-box {
  overflow: hidden;
  width: 97%;
  height: 100%;
  box-shadow: 0 20px 50px #0000000d;
}

.popular-tour-img-box .popular-img {
  transition: all 0.5s linear;
  width: 100%;
  height: 280px;
  overflow: hidden !important;
}



.popular-tour-img-box .popular-img img {

  transition: all 0.5s linear;
  width: 100%;
  height: 100%;
}

.popular-tour-img-box img:hover {
  height: 280px !important;
  transform: scale(1.2);
  overflow: hidden !important;
}

.popular-tour-img-box .tour-content {
  padding: 22px;
  position: relative;
  padding-top: 31px;
}

.best-seller {
  position: absolute;
  left: 19px;
  background: #9F181F;
  color: white;
  padding: 6px 15px;
  top: -19px;
  font-weight: 600;
}

.popular-tour-img-box .tour-content .review-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 7px;
}

.event-icon {
  color: #9F181F;
  margin-right: 10px;
}

.clock-icon {
  color: #9F181F;
  margin-right: 10px;
}

.star-icon {
  color: orange;
  margin: 0 3px;
}

.heading-h4 {
  color: #9F181F;
  float: right;
  font-weight: 800;
  padding-bottom: 15px;
}

.offers-container {
  background-image: linear-gradient(141deg, #9F181F, #ff8188);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.offers-container h3 {
  color: #ffffff;
  font-size: 30px;
}

button {
  border: none;
  outline: none;
}

.offers-container h2 {
  color: white;
  font-weight: 700;
  font-size: 44px;
}

.offers-container button {
  background: black;
  color: white;
  padding: 14px 60px;
  margin: 5px 0;
  border: 1px solid black;
  font-size: 18px;
  font-weight: 700;
}

.offers-container button:hover {
  background-color: transparent;
  border: 1px solid white;
}

.offers-container h5 {
  color: #ffffff;
  margin-top: 10px;
  font-size: 23px;
}

.offers-container p {
  color: #ffffff;
  font-weight: 800;
}

.travel-heighlight-section {
  background-image: url("./assets/kedarnath/kedarnath16.webp");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  height: 100%;
  position: relative;
  z-index: 1;
  opacity: 1;
}

.travel-heighlight-section::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.5;
  z-index: -1;
}

.travel-heighlight-section .heighlight-container {
  padding: 50px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.travel-heighlight-section .heighlight-container h2 {
  font-size: 50px;
}

.travel-heighlight-section .heighlight-container p {
  font-size: 20px;
  color: white;
  margin-top: 10px;
}

.customer-review-box {
  background: #9F181F;
  color: white;
  padding: 40px;
  text-align: start;
}

.heading-h2 {
  font-size: 42px;
  font-weight: 700;
}

.customer-review-box .para {
  margin: 18px 0;
  font-style: italic;
}

.customer-review-box h3 {
  padding-bottom: 10px;
}

.customer-review-box div h5 {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.customer-review-box .customer-block {
  display: flex;
}

.customer-review-box .customer-block img {
  width: 50px;
  border-radius: 50%;
  height: 50px;
}

.margin-50 {
  margin-top: 70px;
}

.customer-review-box .customer-block div:last-child {
  margin-left: 15px;
}

.customer-review-box .customer-block div:last-child p {
  font-style: italic;
}

/* browser tour section */
.browser-tour-section {
  background: #ececec;
  padding: 70px 0;
}

.browser-tour-section .browser-ul {
  list-style-position: outside;
  margin-left: -30px;
  margin-top: 20px;
}

.browser-tour-section input {
  padding: 13px;
  border: navajowhite;
  position: relative;
  top: -2px;
}

.browser-tour-section .browser-ul li {
  color: #9F181F;
  margin: 7px 0;
  cursor: pointer;
}

.browser-tour-section li {
  list-style: none;
}

.browser-tour-section p {
  color: gray;
}

.social-media-icons {
  margin-top: 15px;
}

.social-media-icons span a {
  color: gray;
  margin: 0 5px;
  cursor: pointer;
  font-size: 18px;
}

.article-section {
  overflow: hidden;
}

.article-img-box {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  opacity: 1;
  z-index: 1;
  transition: all 0.5s linear;
}

.bg-overlay {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.4;
}

.article-img-box:hover {
  transform: scale(1.2);
  transform: translateY(-10px);
}

.search-sugest {
  position: absolute;
  top: 50px;
  background: white;
  z-index: 555555555555555555555 !important;
  height: 20rem;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  text-align: start;
  padding-left: 15px;
  width: 100%;
  padding-top: 5px;
  display: none;
}

.search-sugest.open {
  display: block;
}

.search-sugest li {
  margin-bottom: 8px;
}

.search-sugest li a {
  color: black;
  text-transform: uppercase;
  font-size: 13px;
}

.article-img-box .article-content {
  position: absolute;
  bottom: 0;
  background: rgb(24 24 24 / 80%);
  left: 0;
  /* padding: 15px; */
  text-align: center;
  width: 100%;
  color: white;
  /* display: flex; */
  align-items: flex-end;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 0 !important;
  bottom: 0;
  top: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.article-img-box:hover .article-content {
  top: 0;
}

/* ontact-banner */
.contact-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.contact-banner img {
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-banner::before {
  background: black;
  position: absolute;
  content: "";
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  opacity: 0.6;
}

.contact-banner.page::before {
  content: none;
}

.contact-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.contact-container h5 {
  color: white;
}

.contact-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 25px;
}

.contact-content h3 {
  margin-bottom: 40px;
  font-size: 40px;
}

.contact-content p {
  cursor: pointer;
}

.contact-content .para {
  color: gray;
  cursor: auto;
}

.contact-form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.contact-form-section .para {
  color: gray;
  font-size: 20px;
  font-style: italic;
}

.contact-form-container {
  width: 55%;
}

.contact-form-container div:nth-child(1) input {
  width: 47%;
  margin: 10px;
}

.contact-form-container div:nth-child(2) input {
  width: 97%;
  margin: 10px;
}

.contact-form-container div:nth-child(3) input {
  width: 97%;
  margin: 10px;
}

textarea:focus {
  outline: navajowhite;
}

.contact-form-container div:nth-child(4) textarea {
  width: 97%;
  margin: 10px;
  border: 1px solid rgb(211, 211, 211);
  padding: 10px;
}

.contact-form-container div:nth-child(5) button {
  width: 97%;
  margin: 10px;
  /* padding: 10px; */
}

/* about section */
.about-banner {
  height: 75vh;
  width: 100%;
  position: relative;
  z-index: 1;
  opacity: 1;
}

.about-banner::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.5;
}

.about-banner.page::before {
  content: none;
}

.about-banner img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  object-fit: fill;
}

.about-banner div div {
  position: absolute;
  top: -125px;
  color: white;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  flex-direction: column;

}

.about-banner div div p {
  color: white;
  font-weight: 800;
  display: flex;
  align-items: center;
}

.about-banner div div p a {
  color: white;
}

.about-banner div div p a svg {
  margin-right: 2px;
  font-size: 14px;
  font-weight: bold;
}

.para-gray {
  color: gray;
}

.about-section-2 {
  background-image: url("./assets/about-banner-2.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  height: 100%;
  position: relative;
  z-index: 1;
  opacity: 1;
  padding: 50px;
}

.about-section-2::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.7;
  z-index: -1;
}

.about-section-2 .about-content {
  color: white;
  z-index: 1;
  text-align: center;
  padding: 50px;
}

.about-section-2 .about-content ul {
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin-left: 30%;
  margin-top: 15px;
}

.about-service-section {
  display: flex;

}

.about-service-section div {
  width: 50%;

}

.about-service-section div:first-child {
  padding: 70px;
  margin-left: 80px;
}

.about-service-section div:last-child {
  background-color: rgba(0, 0, 0, 0.826);
  color: white;
  padding: 100px;
  padding-top: 50px;
}

.about-service-section div:last-child h5 {
  font-size: 25px;
  margin-top: 30px;
  margin-bottom: 5px;
}

/* team  */
.team-img-box {
  overflow: hidden;
  width: 97%;
  height: 100%;
  box-shadow: 0px 20px 50px #0000000d;

}

.team-img-box .team-img {
  overflow: hidden;
  transition: all 0.5s linear;
  width: 100%;
  height: 350px;
}

.team-img-box .team-img img {

  transition: all 0.5s linear;
  width: 100%;
  height: 100%;
}

.team-img-box img:hover {
  height: 350px;
  transform: scale(1.2);
  overflow: hidden;
}

.team-img-box .team-content {
  padding: 22px;
  text-align: center;
}

.tour-banner-content {
  width: 80%;
  text-align: center;
}