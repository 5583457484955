@media screen and (max-width:1450px) and (min-width:1300px) {

    .texture-area {
        width: 90%;

    }
}

@media screen and (max-width:1300px) and (min-width:1200px) {
    .contact-form-container div:nth-child(1) input {
        width: 97%;
        margin: 10px;
    }

    .texture-area {
        width: 90%;

    }

    .container-section {
        width: 90%;
    }

    .texture-content {

        padding: 20px 7px;
    }
}

.title h5 {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
}

.phone-mode {
    display: none;
}

@media screen and (max-width:1200px) and (min-width:1000px) {
    .contact-form-container div:nth-child(1) input {
        width: 97%;
        margin: 10px;
    }

    .texture-area {
        width: 90%;

    }

    .container-section {
        width: 90%;
    }

    .navbar {
        padding: 0 48px;
    }

    .team-img-box .team-img {
        height: 265px;
    }

    .popular-tour-img-box .tour-content {
        padding: 16px;
    }

    .popular-tour-img-box .popular-img {
        height: 260px;
    }

    .popular-tour-img-box .tour-content .mb-4 {
        margin-bottom: 10px !important;
    }

    .popular-tour-img-box .tour-content .review-content {

        margin-top: 4px;
    }

    .heading-h4 {
        font-size: 20px;
    }

    .title h5 {
        padding: 17px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    .content.show .fs-5 {
        font-size: 18px !important;
    }
}


@media screen and (max-width:767px) and (min-width:480px) {

    .navbar-container-section .right {

        width: 100% !important;
        top: 0 !important;
        max-width: 100% !important;
        display: flex;
        left: 0 !important;
        right: 0 !important;
        flex-direction: column !important;
        height: 100% !important;
        justify-content: center;
        position: relative !important;
        max-height: 100% !important;
        transform: none !important;
    }

    .navbar-container-section .pin-spacer {
        width: 100% !important;
        height: 100% !important;
        max-height: 100% !important;
    }

    .navbar-container-section {
        flex-direction: column;
    }

    .tryp-detail-banner img {
        height: 100%;
        object-fit: scale-down;
    }

    .tryp-detail-banner {
        height: 100%;
    }

    .navbar-container-section .left {
        width: 100%;
    }

}

@media screen and (max-width:480px) and (min-width:350px) {
    .slider-search-container {
        flex-direction: column;
    }

    .home-banner .slider-container {

        top: -22px;
    }

    .home-banner .slider-container h1 {
        font-size: 30px;
        line-height: 21px;
    }

    .home-banner .slider-container p {
        color: white;
        font-size: 16px;
    }

    .slider-search-container select {
        margin: 5px 0;
    }

    .home-banner .slider-container input {
        width: 280px;
    }

    .home-banner .slider-container select {
        width: 280px;
    }

    .popular-tour-img-box .tour-content div div:last-child {
        display: flex;
        justify-content: space-between;
        margin-top: 9px;
    }

    .popular-tour-img-box .tour-content .mb-4 {
        margin-bottom: 10px !important;
    }

    .popular-tour-img-box .tour-content .review-content {
        margin-top: 5px !important;
    }

    .popular-tour-img-box .tour-content {
        padding-bottom: 4px;
    }

    .phone-mode {
        display: block;
    }

    .laptop-mode {
        display: none;
    }

    .blur-text {
        font-size: 50px;

        top: -3px;
    }

    .margin-50 {
        margin-top: 18px;
    }

    .mt-5 {
        margin-top: 18px !important;

    }

    .margin-100 {
        margin-top: 50px !important;
    }

    .container-section {
        width: 90%;
        margin: auto;
    }

    .pe-0 {
        padding: 0 !important;
    }

    .ps-0 {
        padding: 0 !important;
    }

    .offers-container {
        padding: 28px 0;
    }

    .heading-h2 {
        font-size: 30px !important;
        font-weight: 700;
    }

    .texture-area {
        display: none;
    }

    .about-banner {
        height: 34vh;

    }

    .about-section-2 {
        padding: 50px 0;
    }

    .about-section-2 .about-content {
        padding: 0;
    }

    .about-section-2 .about-content ul {
        margin: 0;
    }

    .about-service-section {

        flex-direction: column;
    }

    .about-service-section div:first-child {
        margin: 0;
        padding: 21px;
    }

    .about-service-section div {
        width: 100%;
    }

    .about-service-section div:last-child {
        padding: 30px;
        margin-top: 35px;
    }

    .about-service-section div:last-child h5 {
        font-size: 20px;
    }

    .para {
        font-size: 15px;
    }

    .ms-4 {
        margin-left: 0rem !important;
    }

    .contact-form-container {
        width: 100%;
    }

    .contact-form-container div:nth-child(1) input {
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
    }

    .contact-form-container div:nth-child(2) input {
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
    }

    .contact-form-container div:nth-child(3) textarea {
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
        height: 90px;
    }

    .contact-form-container div:nth-child(4) button {
        width: 100%;
        margin: 0;
    }

    .popular-tour-img-box .tour-content .d-flex {
        flex-direction: column;
    }

    .proceed-book-section input,
    .proceed-book-section select {
        margin-top: 18px;
    }

    .home-banner {
        height: 56vh;
    }

    .slider-search-container {
        padding-top: 11px;
        display: none !important;
    }

    .popular-img-box {
        height: 300px;
    }
}










@media screen and (max-width:480px) and (min-width:400px) {

    .navbar-container-section .right {

        width: 100% !important;
        top: 0 !important;
        max-width: 100% !important;
        display: flex;
        left: 0 !important;
        right: 0 !important;
        flex-direction: column !important;
        height: 100% !important;
        justify-content: center;
        position: relative !important;
        max-height: 100% !important;
        transform: none !important;
    }

    .navbar-container-section .pin-spacer {
        width: 100% !important;
        height: 100% !important;
        max-height: 100% !important;
    }

    .navbar-container-section {
        flex-direction: column;
    }

    .tryp-detail-banner img {
        height: 100%;
        object-fit: scale-down;
    }

    .tryp-detail-banner {
        height: 100%;
    }

}


@media screen and (max-width:400px) and (min-width:350px) {
    .container-section {
        width: 90%;
    }

    .heading-h2 {
        font-size: 35px;
    }

    .tryp-detail-banner {
        height: 100%;
    }

    .tryp-detail-banner img {
        height: 100%;
        object-fit: scale-down;
    }

    .navbar-container ul {
        display: flex;
        flex-wrap: wrap;
    }

    .photo-slider img {
        height: 30vh;
    }

    .popular-tour-img-box .tour-content .d-flex {
        flex-direction: column;
    }

    .navbar-container ul li {
        padding: 0;
        padding-top: 20px;
        padding-right: 25px;
    }

    .navbar-container-section {

        flex-direction: column;
    }

    .navbar-container-section .left {
        width: 100%;
        padding-right: 0px;
    }

    .navbar-container-section .right {

        width: 100% !important;
        top: 0 !important;
        max-width: 100% !important;
        display: flex;
        left: 0 !important;
        right: 0 !important;
        flex-direction: column !important;
        height: 100% !important;
        justify-content: center;
        position: relative !important;
        max-height: 100% !important;
        transform: none !important;
    }

    .navbar-container-section .pin-spacer {
        width: 100% !important;
        height: 100% !important;
        max-height: 100% !important;
    }

    .title h5 {

        font-size: 16px;
        padding: 10px 16px;
    }

    .content.show .fs-5 {
        font-size: 16px !important;
    }

    .item.items {
        padding: 7px 0 !important;
        margin: 0 !important;
    }
}