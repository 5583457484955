.wrapper {
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.accordion {
    /* width: 500px; */
}

.item {
    background-color: #3944a5;
    /* background-color: cadetblue; */
    /* margin-bottom: 5px; */
    padding: 10px 0;
}

.title {
    color: #85662b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: #8080803d;
}

.title h5 {
    color: rgb(44, 44, 44);
    font-size: 17px !important;
    font-family: sans-serif;
    padding: 18px 20px;
    font-weight: 700 !important;
}

.title span {
    color: white;
    font-size: 33px;
}

.title h5 span {
    font-size: 11px;
    margin-right: 7px;
    color: black;
}

.title .heading-h5.active span {
    color: white;
}

.content {
    color: gray;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content.show {
    height: auto;
    max-height: 999px;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
    line-height: 1.5;
    padding-top: 12px;
}

.content.show p {
    margin-bottom: 5px;
    display: flex;
    margin-left: 5px;
}

.content.show p span {
    margin-right: 5px;
}

.content.show p span svg {
    /* color: black; */
}

.item.items {
    background-color: white;

    font-family: 'Open Sans';
    /* border-bottom: 1px solid; */
    /* padding-bottom: 28px; */
    color: #000;
}



.title.active {
    background-color: #9F181F;
}

.title h5.active {
    color: white;
}