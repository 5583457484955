.tryp-detail-banner {
    height: 80vh;
}

.details-navbar {
    border-bottom: 1px solid gray;
}

.navbar-container ul {
    display: flex;
}

.navbar-container ul li {
    padding: 20px;
    font-size: 17px;
    font-weight: 600;
}

.ul {
    list-style-type: none;
}

.clock-icon.green {
    color: green;
}

.time-people {
    display: flex;
    align-items: center;
}

.time-people span svg {
    font-size: 22px;
}

.navbar-container-section {
    display: flex;
    /* outline: 1px solid #9F181F; */
}

.navbar-container-section .left {
    width: 70%;
    padding-right: 35px;
}

.navbar-container-section .detailsWrapper {
    margin: auto;
    width: 100%;
}

.navbar-container-section .details {
    /* height: 50vh; */
    /* outline: 1px solid green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.navbar-container-section .right {
    /* outline: 1px solid purple; */
    width: 30%;
    /* height: 600px !important; */
    height: 100vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.photo-slider img {
    height: 70vh;
}

.photo-slider .swiper-button-next,
.swiper-rtl .swiper-button-prev {
    background: rgba(0, 0, 0, .6);
    padding: 10px;
    font-size: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.photo-slider .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 18px;
    color: white;
}

.photo-slider .swiper-button-prev,
.swiper-rtl .swiper-button-next {
    background: rgba(0, 0, 0, .6);
    padding: 10px;
    font-size: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.photo-slider .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    content: 'prev';
    font-size: 18px;
    color: white;
}

/* right section */
.proceed-book-section {
    box-shadow: 0 20px 50px #0000000d;
    padding: 40px;
    padding-bottom: 50px;
    background-color: white;
}

.proceed-book-section .prices span {
    font-size: 31px;
    font-weight: 700;
}

.proceed-book-section p {
    font-weight: 600;
    font-size: 21px;
}

.proceed-book-section .prices span svg {
    color: #9F181F;
    margin-right: 10px;
}

.proceed-book-section input,
.proceed-book-section select {
    width: 100%;
    padding: 10px;
    margin-top: 15px;
}

.proceed-book-section input:focus,
.proceed-book-section select {
    border: 1px solid gray;
}

.proceed-book-section button {
    margin-top: 20px;
    width: 100%;
}

.why-booj-us {
    margin-top: 40px;
    background-color: #dcdcdc85;
    padding: 40px 0;
    box-shadow: 0 20px 50px #0000000d;
}

.why-booj-us h6 {
    padding: 0 40px;
}

.why-booj-us .ins {
    margin-bottom: 5px;
    display: flex;
    position: relative;
    align-items: center;
    padding: 13px 40px;

}

.why-booj-us .ins::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    border-bottom: 1px dashed gray;
    width: 100%;
}

.why-booj-us .ins svg {
    color: #9F181F;
    font-size: 21px;
    margin-right: 5px;
}

.get-question {
    margin-top: 40px;
    background-image: url("../assets/gg.avif");
    height: 300px;
    position: relative;
    z-index: -1;
    /* filter: brightness(0.5); */
}

.pin-spacer {
    height: 1500px !important;
}

.pin-spacer .right {
    height: 1500px !important;


}

.over-lay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: -1;
}

.get-question .cont {
    position: relative;
    z-index: 1;
    filter: brightness(1);
    color: white;
    padding: 30px;
}

.get-question .cont a {
    color: white;
}

.get-question .cont svg {
    color: #9F181F;
    margin-right: 5px;
    font-size: 23px;
}

.fixed-right {
    bottom: 0;
    right: 8%;
    position: fixed;
    width: 27%;
}