/* src/FormPopup.css */
.home-popup .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

.home-popup .popup-inner {
    position: relative;
    padding: 20px;
    background-color: #fff;
    width: 80%;
    max-width: 600px;
}

.home-popup .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.home-popup .form-group {
    margin-bottom: 15px;
}

.home-popup label {
    display: block;
    margin-bottom: 5px;
}

.home-popup input[type="text"],
.home-popup input[type="email"],
.home-popup textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.home-popup button[type="submit"] {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.home-popup button[type="submit"]:hover {
    background-color: #0056b3;
}