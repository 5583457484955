footer {
    /* background-color: #303030; */
    background-color: black;
    padding: 50px 0;
    color: white;
}

.footer-logo {
    width: 211px;
    height: 79px;
}

footer li {
    list-style: none;
    margin: 7px 0;

}

footer li:hover {
    cursor: pointer;
    color: #9F181F;
}

.ul {
    margin-left: -30px;
}

footer h5 {
    color: #9F181F;
}

.copyright-section {
    background: #1e1e1e
}

.copyright-container {
    display: flex;
    color: white;
    justify-content: space-between;
    padding: 25px 0;
    padding-bottom: 15px;
}